<template>
  <div>
    <a-drawer :title="`${form.id ? '编辑' : '新建'}菜单`" :width="720" :visible="visible"
      :body-style="{ paddingBottom: '80px' }" :footer-style="{ textAlign: 'right' }" @close="onClose">
      <a-form :model="form" :rules="rules" layout="vertical">
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="名称" name="mc">
              <a-input v-model:value="form.mc" placeholder="请输入" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="排序" name="sort_num">
              <a-input type="number" v-model:value="form.sort_num" placeholder="请输入" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="跳转路径" name="url">
              <a-input v-model:value="form.url" placeholder="请输入" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="是否快捷菜单" name="is_quick_menu">
              <a-select v-model:value="form.is_quick_menu" placeholder="请选择">
                <a-select-option value="1">是</a-select-option>
                <a-select-option value="0">否</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="附件" name="icon">
              <UploadFile v-model:value="form.icon" />
            </a-form-item>
          </a-col>
        </a-row>

      </a-form>
      <template #extra>
        <a-space>
          <a-button @click="onClose">取消</a-button>
          <a-button :loading="createLoading" type="primary" @click="onConfirm">提交</a-button>
        </a-space>
      </template>
    </a-drawer>
  </div>
</template>
<script>
import UploadFile from '@/components/common/uploadFile.vue';
import service from '@/service/service';
import { message } from 'ant-design-vue';
const rules = {
  mc: [{
    required: true,
    message: '请输入名称',
  }],
};
export default {
  components: {
    UploadFile
  },
  props: ['current'],
  data() {
    return {
      rules,
      form: {
        pid: '',
        mc: '',
        icon: '',
        url: '',
        id:'',
        is_quick_menu: '',
        sort_num:''
      },
      visible: false,
      createLoading: false,
    }
  },
  watch: {
    current: {
      handler(nowCurrent) {
        if (nowCurrent) {
          let temp = {};
          for (let key in this.form) {
            temp[key] = nowCurrent[key];
          }
          this.form = temp;
        }
      },
      immediate: true
    }
  },
  methods: {
    onClose() {
      this.visible = false;
      this.onReset();
      this.$emit('close');
    },
    onReset() {
      let temp = {};
      for (let key in this.form) {
        temp[key] = '';
      }
      this.form = temp;
    },
    onShow() {
      this.visible = true;
    },
    async onConfirm() {
      this.createLoading = true;
      const api = this.form.id ? 'edit_menu' : 'add_menu';
      const { code, msg } = await service[api]({
        ...this.form
      })
      this.createLoading = false;
      if (code && code == '1') {
        message.success(msg || '成功');
        this.$emit('refreshList');
        this.onClose();
      }
    }
  }
}
</script>